
.next{
    background-color: #b1d7fa;
    border-left: #57aeff 8px solid;
    border-radius: 5px;
    padding: 2px 2px 2px 5px;
    margin-bottom: 2px;

}
.past{
    background-color: #E4F8F0;
    border-left: #1EA97C 8px solid;
    border-radius: 5px;
    padding: 2px 2px 2px 5px;
    margin-bottom: 2px;
}