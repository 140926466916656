.actif{
    background-color: #E4F8F0;
    border-left: #1EA97C 8px solid;
    border-radius: 5px;
    padding: 2px 2px 2px 5px;
}
.inactif{
    background-color: #FFE7E6;
    border-left: #FF5757 8px solid;
    border-radius: 5px;
    padding: 2px 2px 2px 5px;

}