
.section{
    
    border-left: #b8b5b5 5px solid;
    border-right: #b8b5b5 1px solid;
    border-top: #b8b5b5 1px solid;
    border-bottom: #b8b5b5 2px solid;
    border-radius: 5px;
    padding: 5px;

}